import React from 'react'
import { Loader as LoaderCore } from '@mantine/core'

import { $ } from '@utils/styles'

type LoaderProps = {
  className?: string
  children?: React.ReactNode
}

const LoaderText = (props: LoaderProps) => {
  const { className, children } = props
  return (
    <div
      className={$(
        'cd-w-full cd-h-full cd-flex cd-items-center cd-align-middle cd-justify-center cd-flex-row cd-gap-y-[1rem] cd-grow',
        className,
      )}
    >
      <div className="cd-flex cd-flex-col cd-items-center cd-gap-x-[0.5rem]">
        <LoaderCore color="blue" type="dots" />
        {children}
      </div>
    </div>
  )
}

export default LoaderText
