/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import moment from 'moment'
import { Carousel } from '@mantine/carousel'
import { IconDownload } from '@tabler/icons-react'
import { Button, Divider, Image, Modal, Paper, Table, Text, Title } from '@mantine/core'

import { ToolBaseType } from '@customTypes/tool'

import DataRepo from '@api/datasource/data'

import { DATE_FORMAT } from '@constants/app'

import NumberFormat from '@components/shared/Number'

import { useQuery } from '@tanstack/react-query'
import QueryKeys from '@constants/queryKeys'
import { ErrorService } from '@utils/error'
import LoaderText from '@components/shared/loader'
import { isLoadingOrRefetchQuery } from '@utils/network'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'
import { ModalProps } from '@customTypes/page'

import './preview.scss'
import { downloadAttachment } from '@utils/firebase'

type FormProps = {
  toolID?: string
}

const ToolPreview = (props: ModalProps<ToolBaseType> & FormProps) => {
  const { open, modal, toolID, data, size = 'lg', onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [imageFull] = React.useState<string | null>(null)

  const toolFallback = useQuery<ToolBaseType, ErrorService, ToolBaseType, string[]>({
    enabled: Boolean(toolID),
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_TOOL_KEY, String(toolID)],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.constructionEntitiesService.getToolBaseById(queryKey[1])

      return response
    },
  })

  const { name, alertStock, description, value, updatedAt, attachments } =
    toolID && toolFallback.data ? toolFallback.data : (data ?? ({} as ToolBaseType))

  const content = (toolFallback.data || data) && (
    <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
      {!modal && <Title className="cd-text-2xl cd-font-bold">{name}</Title>}

      <Divider />

      <div>
        <Text className="cd-text-base">{description}</Text>
      </div>

      <div>
        <Text className="cd-text-base cd-my-[0.5rem]">
          El campo &quot;Alerta&quot; no corresponde al stock actual del material, sino a la
          cantidad mínima. Para visualizar el stock debe consultar en la visual de Bodegas y ver el
          stock de la herramienta para esa bodega
        </Text>
      </div>

      <Paper withBorder>
        <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
          <Table striped withColumnBorders withRowBorders className="cd-w-full">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Valor</Table.Th>
                <Table.Th>Alerta</Table.Th>
                <Table.Th>Actualizado</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>{<NumberFormat prefix="" value={value} />}</Table.Td>
                <Table.Td>{<NumberFormat skipRole prefix="" value={alertStock} />}</Table.Td>
                <Table.Td>{moment.unix(updatedAt).format(DATE_FORMAT)}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>

      {Boolean(attachments.length) && (
        <div>
          <Text className="cd-text-base cd-mb-[0.5rem]">Archivos adjuntos</Text>

          {attachments.length === 1 && (
            <div className="cd-flex cd-flex-col cd-justify-center cd-relative">
              <Image
                alt={name}
                className="cd-flex-none cd-object-contain cd-h-[400px] cd-w-auto"
                radius="sm"
                src={attachments[0]}
                //onClick={() => setImageFull(attachments[0])}
              />
              <div className="cd-flex cd-justify-center cd-mt-[0.5rem]">
                <Button
                  color="green"
                  leftSection={<IconDownload size={20} />}
                  onClick={() => downloadAttachment(attachments[0], name)}
                >
                  Descargar
                </Button>
              </div>
            </div>
          )}

          {attachments.length > 1 && (
            <Carousel withControls className="control " slideGap="md" style={{ flex: 1 }}>
              {attachments.map((attachment, index) => (
                <Carousel.Slide className="cd-flex cd-justify-center" key={index}>
                  <div className="cd-flex cd-flex-col cd-justify-center cd-relative">
                    <Image
                      alt={name}
                      className="cd-flex-none cd-object-cover cd-h-[400px] cd-w-auto"
                      radius="sm"
                      src={attachment}
                      //onClick={() => setImageFull(attachment)}
                    />

                    <div className="cd-flex cd-justify-center cd-mt-[0.5rem]">
                      <Button
                        color="green"
                        leftSection={<IconDownload size={20} />}
                        onClick={() => downloadAttachment(attachment, `${name}-${index + 1}`)}
                      >
                        Descargar
                      </Button>
                    </div>
                  </div>
                </Carousel.Slide>
              ))}
            </Carousel>
          )}
        </div>
      )}
      <Modal centered fullScreen opened={Boolean(imageFull)} size="xl" onClose={() => null}>
        <div className="cd-flex cd-justify-center">
          <Image alt={name} className="cd-basis-[100%]" radius="md" src={imageFull} w="100%" />
        </div>
      </Modal>
    </div>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={modal ? name : undefined}
        withCloseButton={modal}
        onClose={() => outerOnClose?.()}
      >
        {toolID && isLoadingOrRefetchQuery(toolFallback) && (
          <LoaderText>
            <Text c="dimmed" className="cd-text-sm">
              Cargando información
            </Text>
          </LoaderText>
        )}
        {(data || toolFallback.data) && content}
      </Modal>
    )
  }

  return content
}

export default ToolPreview
