/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'

import { ReceiptBlockType } from '@customTypes/block'
import { GetReceiptsType, ReceiptBaseType } from '@customTypes/receipt'

import { MeasuredUnit } from '@constants/rawMaterial'

import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import { advancedSearch } from './form'
import { ListFormatter, periodString } from './string'

type SearchParams<T> = {
  params: GetReceiptsType
  data?: T[]
}

export const onFilterReceipt = <T extends ReceiptBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
  } = params

  if (!query || !field || !data) {
    return data
  }

  if (['name', 'description'].includes(field)) {
    const keyField = field as 'name' | 'description'
    return data.filter((item) =>
      advancedSearch({ search: query.toLowerCase(), label: item[keyField].toLowerCase() }),
    )
  }

  if (field === 'uid') {
    return data.filter((item) => item.uid.startsWith(query))
  }

  return data
}

type ReceiptResumenParams = {
  index?: number
  reduced?: boolean
  receipt: ReceiptBlockType
  userRole: string
  handleInput?: (path: string, value: number | string) => void
}

export const getReceiptResumen = (params: ReceiptResumenParams) => {
  const { receipt, reduced, index, userRole, handleInput } = params
  const { rawMaterials } = receipt

  if (reduced) {
    return periodString(
      `La oferta incluye: ${ListFormatter.format(
        rawMaterials.map(
          (material) =>
            `${material.name} (${material.quantity} ${MeasuredUnit[material.measure].value})`,
        ),
      )}`,
    )
  }

  return (
    <React.Fragment>
      <ol>
        {rawMaterials.map((material, idx) => {
          return (
            <li className="cd-my-[0.75rem]" key={material.uid}>
              {material.name}: {material.quantity} {MeasuredUnit[material.measure].value}
              {handleInput && (
                <Input
                  defaultValue={material.value}
                  disabled={userRole !== 'admin'}
                  display={userRole !== 'admin' ? 'none' : 'block'}
                  min={0}
                  prefix="$"
                  readOnly={userRole !== 'admin'}
                  typeInput="number"
                  variant="default"
                  onChange={(value) =>
                    handleInput(`items.${index}.rawMaterials.${idx}.value`, value)
                  }
                />
              )}
              {!handleInput && <span>. Valor: </span>}
              {!handleInput && <NumberFormat value={material.value} />}
            </li>
          )
        })}
      </ol>
    </React.Fragment>
  )
}
