import React from 'react'
import { useForm } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { IconSearch } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Divider, Loader, Modal, Table, Text } from '@mantine/core'

import {
  ReceiptType,
  ReceiptBaseType,
  CreateReceiptType,
  UpdateReceiptType,
  CreateReceiptSchema,
  RawMaterialReceiptType,
} from '@customTypes/receipt'
import { ModalFormType, ModalProps } from '@customTypes/page'
import { RawMaterialBaseType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

//import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { ErrorService } from '@utils/error'
import { getTotal } from '@utils/rawMaterial'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import RawMaterialForm from '@components/rawMaterial/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'
import { LIMIT_SELECT_SEARCH } from '@constants/app'

export type ReceiptFormType = {
  receiptIDs: string[]
  receipt?: ReceiptBaseType
}

const INITIAL: CreateReceiptType = {
  identifier: '',
  name: '',
  description: '',
  descriptionProforma: '',
  rawMaterials: [],
}

const ReceiptForm = (props: ModalProps<ReceiptFormType, ReceiptType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [modalForm, setModalForm] = React.useState<ModalFormType<RawMaterialBaseType>>({
    opened: false,
  })

  const [selectedRows, handlers] = useListState<string>([])

  const formReceipt = useForm<CreateReceiptType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateReceiptSchema),
  })

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService>({
    initialData: [],
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      return await DataRepo.constructionEntitiesService.getRawMaterials({
        orderDir: 'asc',
      })
    },
  })

  const receiptCreateMutation = useMutation<ReceiptType, ErrorService, CreateReceiptType>({
    mutationFn: async (values) => {
      const response = await DataRepo.constructionEntitiesService.createReceipt(values)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_RECEIPTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.(response)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la receta',
        })
      }

      onClose()

      setModalForm({ opened: false })
    },
  })

  const receiptUpdateMutation = useMutation<void, ErrorService, UpdateReceiptType>({
    mutationFn: async (values) => {
      const response = await DataRepo.constructionEntitiesService.updateReceipt(values)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_RECEIPT_KEY, data?.receipt?.uid],
        refetchType: 'all',
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_RECEIPTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.()

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la receta',
        })
      }

      onClose()
    },
  })

  React.useEffect(() => {
    if (open && data?.receipt) {
      formReceipt.setValues(data.receipt)
    } else if (!open) {
      formReceipt.setValues(INITIAL)
      handlers.setState([])
    }
    formReceipt.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data?.receipt])

  const { rawMaterials } = formReceipt.getValues()

  const isLoading = isLoadingMutation(receiptCreateMutation, receiptUpdateMutation)

  const content = data && (
    <form
      onSubmit={formReceipt.onSubmit((values) => {
        if (data.receipt) {
          receiptUpdateMutation.mutate({
            current: values,
            previous: data.receipt,
          })
        } else {
          receiptCreateMutation.mutate(values as CreateReceiptType)
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Input
          uppercase
          label="ID"
          placeholder="Ingrese el ID de la receta"
          typeInput="text"
          {...formReceipt.getInputProps('identifier')}
          onBlur={() => {
            if (validateID(formReceipt.values.identifier)) {
              formReceipt.validateField('identifier')
            }
          }}
        />

        <Input
          label="Nombre"
          placeholder="Ingrese el nombre de la receta"
          typeInput="text"
          {...formReceipt.getInputProps('name')}
          onBlur={() => formReceipt.validateField('name')}
        />

        <Input
          autosize
          label="Descripción"
          maxRows={4}
          minRows={2}
          placeholder="Describa la receta"
          typeInput="textarea"
          {...formReceipt.getInputProps('description')}
          onBlur={() => formReceipt.validateField('description')}
        />

        <Input
          autosize
          label="Descripción de proforma"
          maxRows={6}
          minRows={2}
          placeholder="Describa la receta para la proforma"
          typeInput="textarea"
          {...formReceipt.getInputProps('descriptionProforma')}
          onBlur={() => formReceipt.validateField('descriptionProforma')}
        />

        <Divider
          className="cd-mt-[1rem]"
          label={
            <Text c="dimmed" className="cd-text-base">
              Materias prima
            </Text>
          }
        />

        <Input
          clearable
          searchable
          data={buildSelectOptions<RawMaterialBaseType>({
            data: rawMaterialsQuery.data.filter(
              (item) => !rawMaterials.some((rm) => rm.uid === item.uid),
            ),
            value: 'uid',
            label: 'name',
          })}
          filter={(input) =>
            filterSelect({
              limit: LIMIT_SELECT_SEARCH,
              search: input.search,
              options: input.options,
            })
          }
          nothingFoundMessage={
            <div>
              <Text c="dimmed" className="cd-text-sm">
                {rawMaterialsQuery.data.length
                  ? 'No se pueden agregar materias primas existentes'
                  : 'No hay materias primas'}
              </Text>
              <Button
                className="cd-mt-[1rem]"
                color="blue"
                size="xs"
                variant="light"
                onClick={() => setModalForm({ opened: true })}
              >
                Crear nueva materia prima
              </Button>
            </div>
          }
          placeholder="Escribe para buscar materia prima existente"
          rightSection={
            isLoadingOrRefetchQuery(rawMaterialsQuery) ? (
              <Loader size={18} />
            ) : (
              <IconSearch size={18} />
            )
          }
          typeInput="select"
          withCheckIcon={false}
          onChange={(value) => {
            const rawMaterial = rawMaterialsQuery.data?.find((item) => item.uid === value)

            if (!rawMaterial) return

            const rawMaterialReceipt: CreateReceiptType['rawMaterials'][0] = {
              quantity: 1,
              uid: rawMaterial.uid,
              name: rawMaterial.name,
              description: rawMaterial.description,
              descriptionProforma: rawMaterial.descriptionProforma,
              measure: rawMaterial.measure,
              value: rawMaterial.value,
              alertStock: rawMaterial.alertStock,
              attachments: rawMaterial.attachments,
              createdAt: rawMaterial.createdAt,
              updatedAt: rawMaterial.updatedAt,
              deleted: rawMaterial.deleted,
            }

            formReceipt.insertListItem('rawMaterials', rawMaterialReceipt)
          }}
        />

        <TableCustom<RawMaterialReceiptType>
          columns={buildColumns()}
          data={rawMaterials}
          extraRows={
            <Table.Tr className="cd-relative cd-cursor-default" key="extra-row">
              <Table.Td colSpan={4} />
              <Table.Td className="cd-font-bold" colSpan={1}>
                Total
              </Table.Td>
              <Table.Td className="cd-text-right" colSpan={1}>
                <NumberFormat
                  className="cd-font-bold"
                  value={rawMaterials.reduce((acc, item) => acc + item.quantity * item.value, 0)}
                />
              </Table.Td>
            </Table.Tr>
          }
          keyId="uid"
          minWidth={minWidth}
          noDataMessage="Debe agregar al menos una materia prima para guardar la receta"
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            const filtered = rawMaterials.filter((item) => !ids.includes(item.uid))

            if (filtered.length === rawMaterials.length) {
              return notifications.show({
                color: 'yellow',
                title: 'Advertencia',
                message: 'Una receta debe tener al menos una materia prima',
              })
            }

            formReceipt.setFieldValue('rawMaterials', filtered, { forceUpdate: true })
            handlers.setState([])
          }}
        />

        <RawMaterialForm
          modal
          data={modalForm.data}
          open={modalForm.opened}
          size="xl"
          onClose={() => setModalForm({ opened: false })}
          onSubmitted={(rm) => {
            if (!rm) return

            formReceipt.insertListItem('rawMaterials', rm)
          }}
        />

        <Button
          className="cd-mt-[1rem]"
          disabled={!formReceipt.isValid()}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data.receipt ? 'Editar receta' : 'Crear receta'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data?.receipt ? 'Editar receta' : 'Crear receta'}
        onClose={() => {
          if (formReceipt.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '5%', type: 'text' },
      { key: 'measure', label: 'Medida', width: '1%', type: 'text' },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '2%',
        type: 'number',
        inputProps: {
          min: 1,
          defaultValue: (item) => item.quantity ?? 1,
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '2%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
      {
        key: 'calc',
        label: 'Total ($)',
        width: '2%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: ({ quantity, value }) => (
          <NumberFormat
            value={getTotal({
              percentage: 0,
              hasAccessory: false,
              quantity,
              value,
            })}
          />
        ),
      },
    ] as GenericColumnType<RawMaterialReceiptType>[]
  }

  function validateID(value: string) {
    if (data?.receiptIDs.includes(value)) {
      formReceipt.setFieldError('identifier', 'ID ya existe')
      return false
    }

    return true
  }

  function handleMaterialInput(
    key: keyof RawMaterialReceiptType,
    value: number | string | boolean,
    uid: string,
  ) {
    const rawMaterialIdx = rawMaterials.findIndex((item) => item.uid === uid)

    if (rawMaterialIdx === -1) return

    formReceipt.setFieldValue(`rawMaterials.${rawMaterialIdx}.${key}`, value, { forceUpdate: true })
  }

  function onClose() {
    formReceipt.reset()
    setConfirmExit(false)
    outerOnClose?.()
  }
}

export default ReceiptForm
