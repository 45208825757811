import React from 'react'
import { Image } from '@mantine/core'

import { PDF_PROFORMA_ID } from '@constants/app'

import { useStoreBase } from '@store/index'

import { ProformaBaseType, TotalsType } from '@customTypes/proforma'

import { DueDateOptions, ProformaSeinpro } from '@constants/proforma'

import { $ } from '@utils/styles'
import { periodString } from '@utils/string'
import { getDescription, getProformaTotal, getSequentialStr } from '@utils/proforma'

import NumberFormat from '@components/shared/Number'

import './pdf.scss'

type PDFProps = {
  className?: string
  proforma: ProformaBaseType
  totals: TotalsType
}

const ProformaPDF = React.forwardRef<HTMLDivElement, PDFProps>((props) => {
  const { proforma, totals, className } = props

  const { user } = useStoreBase()

  return (
    <div className={className}>
      <div className={$('cd-table-df cd-relative')} id={PDF_PROFORMA_ID}>
        <div className="!cd-z-0">
          <div className="triangle-big" />
          <div className="triangle-middle" />
          <div className="triangle-little" />
          <div className="straight-line" />
        </div>

        {/* Seq cotización */}
        <div className="cd-flex cd-justify-between cd-items-center cd-absolute cd-top-[10rem] cd-left-[1.5rem]">
          <table className="!cd-w-[9rem]">
            <thead>
              <th className="!cd-pb-[0.5rem] cd-pdf-bold cd-seq-box-th">COTIZACIÓN</th>
            </thead>
            <tbody className="cd-bg-white">
              <tr>
                <td className="!cd-text-center !cd-p-0">
                  <div className="cd-seq-box-td !cd-text-center">
                    <p className="cd-mb-[0.1rem] !cd-text-sm">
                      {getSequentialStr(proforma.sequential)}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Logo Seinpro */}
        <div className="cd-flex cd-justify-end">
          <Image alt="logo" className="cd-mb-[0.5rem]" h="auto" src="/SeinproColor.png" w={120} />
        </div>

        {/* Datos Seinpro */}
        <div className="cd-seinpro-info">
          <div className="cd-flex cd-flex-row cd-justify-end cd-gap-x-[0.25rem]">
            <p className="!cd-text-accent-proforma cd-pdf-bold">RUC:</p>
            <p className="!cd-text-accent-proforma">{ProformaSeinpro.RUC}</p>
          </div>
          <div className="cd-flex cd-flex-row cd-justify-end cd-gap-x-[0.25rem]">
            <p className="!cd-text-accent-proforma cd-pdf-bold">Dirección:</p>
            <p className="!cd-text-accent-proforma">{ProformaSeinpro.location}</p>
          </div>
          <div className="cd-flex cd-flex-row cd-justify-end cd-gap-x-[0.25rem]">
            <p className="!cd-text-accent-proforma cd-pdf-bold">Teléfonos:</p>
            <p className="!cd-text-accent-proforma">{ProformaSeinpro.telfs}</p>
          </div>
          <div className="cd-flex cd-flex-row cd-justify-end cd-gap-x-[0.25rem]">
            <p className="!cd-text-accent-proforma cd-pdf-bold">Email:</p>
            <p className="!cd-text-accent-proforma">{ProformaSeinpro.email}</p>
          </div>
        </div>

        {/* Datos del cliente */}
        <div className="cd-box-break cd-flex cd-flex-col cd-gap-y-[1rem] cd-relative cd-mt-[1.25rem] !cd-z-10">
          <div>
            <div className="cd-flex cd-flex-row">
              <div className="cd-client-title cd-basis-[30%] cd-pl-[0.75rem]">
                <p className="cd-pdf-bold">DATOS DEL CLIENTE</p>
              </div>
              <div className="cd-basis-[70%]" />
            </div>
            <div className="cd-border-[#3b4e89] cd-border-2">
              <div className="cd-flex cd-flex-row cd-justify-between cd-px-[0.75rem] cd-pt-[0.25rem]">
                <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem]">
                  <p className="cd-pdf-bold">CLIENTE:</p>
                  <p>{proforma.client.name}</p>
                </div>
                <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem]">
                  <p className="cd-pdf-bold">RUC:</p>
                  <p>{proforma.client.ruc}</p>
                </div>
                {proforma.client.contacts?.length ? (
                  <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem]">
                    <p className="cd-pdf-bold">CONTACTO:</p>
                    <p>
                      {proforma.client.contacts?.find((c) => c.master)?.name ||
                        proforma.client.contacts?.[0].name}
                    </p>
                  </div>
                ) : (
                  <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem] cd-opacity-0">
                    <p className="cd-pdf-bold"> TELÉFONO:</p>
                    <p>{proforma.client.phone}</p>
                  </div>
                )}
              </div>
              <div className="cd-flex cd-flex-row cd-justify-between cd-px-[0.75rem] cd-pb-[0.25rem]">
                <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem]">
                  <p className="cd-pdf-bold">DIRECCIÓN:</p>
                  <p>{proforma.client.location}</p>
                </div>
                <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem]">
                  <p className="cd-pdf-bold">CORREO:</p>
                  <p>{proforma.client.email}</p>
                </div>
                <div className="cd-flex cd-flex-row cd-justify-start cd-gap-x-[0.25rem]">
                  <p className="cd-pdf-bold"> TELÉFONO:</p>
                  <p>{proforma.client.phone}</p>
                </div>
              </div>
              <div className="cd-h-[8px]" />
            </div>
          </div>
        </div>

        {/* Table lista de items */}
        <div className="cd-mt-[0.5rem]">
          <table className="cd-border-[#3b4e89] cd-border-2">
            <thead className="cd-item-header">
              <tr>
                <th className="cd-w-[20%]"></th>
                <th align="left" className="cd-w-[43%] !cd-text-left">
                  <p>DESCRIPCIÓN</p>
                </th>
                <th className="cd-w-[12%]">
                  <p>CANTIDAD</p>
                </th>
                <th className="cd-w-[13%]">
                  <p>P. UNITARIO</p>
                </th>
                <th className="cd-w-[13%] !cd-text-right">
                  <p>TOTAL</p>
                </th>
              </tr>
            </thead>
            <tbody className="cd-border-[#3b4e89]">
              {proforma.items.map((item, index) => (
                <tr
                  className={$(
                    'cd-box-break cd-row-highlight cd-border-items',
                    index === proforma.items.length - 1 && 'cd-table-separator',
                  )}
                  key={`item-${index}`}
                >
                  <td>
                    <p>{item.name}</p>
                  </td>
                  <td>
                    {!['raw-material', 'accessory'].includes(item.type) && (
                      <p>{periodString(item.descriptionProforma)}</p>
                    )}
                    <p>
                      {getDescription({
                        userRole: user?.role ?? '',
                        reduced: true,
                        item,
                      })}
                    </p>
                  </td>
                  <td className="!cd-text-right">
                    <NumberFormat skipRole decimals={0} prefix="" value={item.quantity} />
                  </td>
                  <td className="!cd-text-right">
                    <NumberFormat
                      value={getProformaTotal({
                        itemsProforma: proforma.items,
                        unit: true,
                        item,
                      })}
                    />
                  </td>
                  <td className="!cd-text-right">
                    <NumberFormat
                      value={getProformaTotal({
                        itemsProforma: proforma.items,
                        item,
                      })}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Table de términos y totals */}
        <div className="cd-box-break !cd-mt-[0.5rem] !cd-pt-[1rem]">
          <table className="cd-border-2 cd-border-[#3b4e89]">
            <tbody>
              <tr className="cd-border-items" key="subtotal-row">
                <td className="cd-w-[75%] cd-term-cond">
                  <div>TÉRMINOS Y CONDICIONES</div>
                </td>
                <td className="cd-w-[13%] ">
                  <p className="cd-pdf-bold">Subtotal</p>
                </td>
                <td className="cd-pdf-bold !cd-text-right cd-w-[13%]">
                  <NumberFormat value={totals.subtotal} />
                </td>
              </tr>
              <tr className="cd-border-items" key="desc-row">
                <td className="cd-w-[75%]" rowSpan={4}>
                  <div className="cd-h-full cd-flex cd-flex-col cd-justify-start">
                    <p className="cd-pdf-bold">Garantía</p>
                    <p>{proforma.warranty}</p>

                    <div className="cd-mt-[0.25rem]">
                      <span className="cd-pdf-bold cd-mt-[0.5rem]">Validez de la proforma:</span>{' '}
                      {proforma.validUntil.amount} {DueDateOptions[proforma.validUntil.unit].label}
                    </div>

                    <div className="cd-mt-[0.25rem]">
                      <span className="cd-pdf-bold cd-mt-[0.5rem]">Tiempo de entrega:</span>{' '}
                      {proforma.dueDate.amount} {DueDateOptions[proforma.dueDate.unit].label}
                    </div>
                  </div>
                </td>
                <td className="cd-w-[13%]">
                  <p className="cd-pdf-bold">Descuento ({proforma.discount ?? 0}%)</p>
                </td>
                <td className="cd-pdf-bold !cd-text-right cd-w-[13%]">
                  <NumberFormat value={totals.discount} />
                </td>
              </tr>
              <tr className="cd-border-items" key="net-row">
                <td className="cd-w-[13%]">
                  <p className="cd-pdf-bold">Neto</p>
                </td>
                <td className="cd-pdf-bold !cd-text-right cd-w-[13%]">
                  <NumberFormat value={totals.netTotal} />
                </td>
              </tr>
              <tr className="cd-border-items" key="iva-row">
                <td className="cd-w-[13%]">
                  <p className="cd-pdf-bold">IVA ({proforma.iva ?? 0}%)</p>
                </td>
                <td className="cd-pdf-bold !cd-text-right cd-w-[13%]">
                  <NumberFormat value={totals.iva} />
                </td>
              </tr>
              <tr className="cd-border-items" key="total-row">
                <td className="cd-w-[13%]">
                  <p className="cd-pdf-bold">Total</p>
                </td>
                <td className="cd-pdf-bold !cd-text-right cd-w-[13%]">
                  <NumberFormat value={totals.total} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Table de Descripción */}
        <div className="cd-box-break cd-mt-[0.5rem] cd-pt-[1rem]">
          <table className="cd-border-2 cd-border-[#3b4e89]">
            <thead>
              <th align="left" className="!cd-pb-[0.5rem] cd-pdf-bold !cd-text-left">
                <p>DESCRIPCIÓN</p>
              </th>
            </thead>
            <tbody>
              <tr className="cd-border-items" key="desc-row">
                <td>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: proforma.description,
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
})

ProformaPDF.displayName = 'ProformaPDF'

export default ProformaPDF
