import { NumberFormatter, NumberFormatterProps } from '@mantine/core'
import { useStoreBase } from '@store/index'

type NumberFormatProps = NumberFormatterProps & {
  decimals?: number
  skipRole?: boolean
}

const NumberFormat = (props: NumberFormatProps) => {
  const { decimals = 2, value = 0, skipRole, ...rest } = props

  const { user } = useStoreBase()

  if (!skipRole && (!user || user.role !== 'admin')) {
    return <span>$ --.--</span>
  }

  return (
    <NumberFormatter
      {...rest}
      decimalSeparator={rest.decimalSeparator ?? ','}
      prefix={rest.prefix ?? '$'}
      thousandSeparator={rest.thousandSeparator ?? '.'}
      value={formatNumber(value)}
    />
  )

  function formatNumber(value?: number | string) {
    if (!value) {
      return value
    }

    return Number(value).toFixed(decimals)
  }
}

export default NumberFormat
