import React from 'react'
import {
  List,
  Text,
  Title,
  Table,
  Button,
  Divider,
  Container,
  ActionIcon,
  Fieldset,
} from '@mantine/core'
import { useListState, useLocalStorage } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { IconCubePlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  BlockType,
  GetBlocksType,
  BlockBaseType,
  DeleteReceiptsBlockType,
} from '@customTypes/block'
import { ModalFormType } from '@customTypes/page'
import { GetReceiptsType } from '@customTypes/receipt'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { LIMIT_SELECT_SEARCH, UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'
import { MeasuredUnit } from '@constants/rawMaterial'

import { useParams } from '@hooks/params'

import { getTotal } from '@utils/rawMaterial'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { onFilterReceipt } from '@utils/receipt'
import { ErrorCodes, ErrorService } from '@utils/error'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import ReceiptPreview from '@components/receipt/preview'
import BlockForm, { BlockFormType } from '@components/block/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

type QueryKeysType = (string | null)[]

const Blocks = () => {
  const [modalBlockForm, setModalBlockForm] = React.useState<ModalFormType<BlockFormType>>({
    opened: false,
  })

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalReceiptPreview, setReceiptModalPreview] = React.useState<
    ModalFormType<BlockType['receipts'][0]>
  >({
    opened: false,
  })

  const [modalReceiptDelete, setModalReceiptDelete] = React.useState<
    ModalFormType<BlockType['receipts']>
  >({
    opened: false,
  })

  const [modalBlockDelete, setModalBlockDelete] = React.useState<ModalFormType<BlockType>>({
    opened: false,
  })

  const [selectedRows, handlers] = useListState<string>([])

  const [blockID, setBlockID] = useLocalStorage<string | null>({
    key: 'state-blockID',
    defaultValue: null,
  })

  const [blockIDs, setBlockIDs] = React.useState<string[]>([])

  const [, setParams] = useParams<GetBlocksType>({
    queryKey: QueryKeys.GET_BLOCKS_KEY,
    initialParams: {},
  })

  const [paramsReceipt, setParamsReceipt] = useParams<GetReceiptsType>({
    queryKey: QueryKeys.GET_MATERIALS_KEY,
    initialParams: {},
  })

  const blocksQuery = useQuery<BlockBaseType[], ErrorService, BlockBaseType[]>({
    initialData: [],
    queryKey: [QueryKeys.GET_BLOCKS_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getBlocks({})
      return response
    },
  })

  const blockQuery = useQuery<BlockType, ErrorService, BlockType, QueryKeysType>({
    refetchOnMount: false,
    enabled: Boolean(blockID),
    queryKey: [QueryKeys.GET_BLOCK_KEY, blockID],
    queryFn: async ({ queryKey }) => {
      if (!queryKey[1]) {
        throw ErrorService.get(ErrorCodes.INVALID_PARAMS)
      }

      const response = await DataRepo.constructionEntitiesService.getBlockById(queryKey[1])

      return response
    },
  })

  const blocksDeleteMutation = useMutation<void, ErrorService, string>({
    mutationFn: async (uid) => {
      const response = await DataRepo.constructionEntitiesService.deleteBlock(uid)

      setParams({})

      setBlockID(null)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar la bloque',
        })
      }

      setModalBlockDelete({ opened: false })

      notifications.show({
        color: 'green',
        title: 'Bloque eliminada',
        message: 'Bloque eliminada correctamente',
      })
    },
  })

  const removeReceiptFromBlockMutation = useMutation<void, ErrorService, DeleteReceiptsBlockType>({
    mutationFn: async (data) => {
      const response = await DataRepo.constructionEntitiesService.deleteReceiptsFromBlock(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_BLOCK_KEY, blockID],
        refetchType: 'all',
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_BLOCKS_KEY,
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar las bloques',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Materias primas eliminada',
        message: 'Materias primas eliminada correctamente',
      })

      setModalReceiptDelete({ opened: false })
      handlers.setState([])
    },
  })

  React.useEffect(() => {
    if (!blocksQuery.data) return

    setBlockIDs(blocksQuery.data.map((item) => item.identifier))
  }, [blocksQuery.data])

  const memoFilterReceipt = React.useMemo(
    () =>
      onFilterReceipt<BlockType['receipts'][0]>({
        data: blockQuery.data?.receipts,
        params: paramsReceipt,
      }),
    [paramsReceipt, blockQuery.data?.receipts],
  )

  React.useEffect(() => {
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      className="cd-w-full cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]"
      size="xl"
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Bloques</Text>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() =>
              setModalBlockForm({
                opened: true,
                data: {
                  blockIDs,
                },
              })
            }
          >
            Agregar
          </Button>
        </div>

        <Input
          searchable
          data={buildSelectOptions({
            data: blocksQuery.data,
            value: 'uid',
            label: (item) => `${item.identifier} - ${item.name}`,
          })}
          filter={(input) =>
            filterSelect({
              limit: LIMIT_SELECT_SEARCH,
              search: input.search,
              options: input.options,
            })
          }
          loading={isLoadingOrRefetchQuery(blocksQuery)}
          nothingFoundMessage="No se encontraron bloques"
          placeholder="Seleccione una bloque"
          rightSection={<IconSearch size={18} />}
          typeInput="select"
          value={blockID}
          onChange={(v) => setBlockID(v)}
        />

        <Divider className="cd-my-[1rem]" />

        {blockQuery.data && (
          <div className="cd-flex cd-flex-col cd-gap-[1rem] md:cd-flex-row">
            <div className="md:cd-basis-[80%]">
              <div className="cd-flex cd-flex-col md:cd-gap-y-[0.25rem] md:cd-justify-between md:cd-items-start md:cd-align-top">
                <Title className="cd-text-xl">
                  <Text inherit c="dimmed" component="span">
                    {blockQuery.data.identifier}
                  </Text>
                  {' - '}
                  {blockQuery.data.name}
                </Title>
              </div>
            </div>

            {selectedRows.length === 0 && (
              <div className="cd-flex cd-flex-col md:cd-flex-row cd-grow cd-justify-end cd-gap-x-[0.5rem]">
                <div className="md:cd-basis-[20%] cd-flex md:cd-flex-row cd-gap-[0.5rem] cd-grow cd-justify-end">
                  <Button
                    fullWidth
                    color="blue"
                    disabled={!blockQuery.data || selectedRows.length > 0}
                    onClick={() => {
                      if (!blockQuery.data) return

                      setModalBlockForm({
                        opened: true,
                        data: {
                          block: blockQuery.data,
                          blockIDs,
                        },
                      })
                    }}
                  >
                    Editar bloque
                  </Button>
                  <ActionIcon
                    color="red"
                    disabled={!blockQuery}
                    size={36}
                    variant="light"
                    onClick={() =>
                      setModalBlockDelete({
                        opened: true,
                        data: blockQuery.data,
                      })
                    }
                  >
                    <IconTrash size={18} />
                  </ActionIcon>
                </div>
              </div>
            )}
          </div>
        )}

        {blockQuery.data && (
          <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-gap-x-[1rem] md:cd-flex-row md:cd-gap-y-0 md:cd-w-full">
            <Fieldset className="md:cd-w-1/2" legend="Descripción del item" variant="filled">
              <Text className="cd-text-base">{blockQuery.data.description}</Text>
            </Fieldset>

            <Fieldset className="md:cd-w-1/2" legend="Descripción para proforma" variant="filled">
              <Text className="cd-text-base">
                {blockQuery.data.descriptionProforma || 'Sin descripción para proforma'}
              </Text>
            </Fieldset>
          </div>
        )}

        <TableCustom<BlockType['receipts'][0]>
          allData={blockQuery.data?.receipts}
          columns={buildColumns()}
          data={memoFilterReceipt?.length ? memoFilterReceipt : undefined}
          defaultColumnSearch="name"
          extraRows={
            <Table.Tr className="cd-relative cd-cursor-default" key="extra-row">
              <Table.Td colSpan={6} />
              <Table.Td className="cd-font-bold" colSpan={1}>
                Total
              </Table.Td>
              <Table.Td className="cd-text-right" colSpan={1}>
                <NumberFormat
                  className="cd-font-bold"
                  value={blockQuery.data?.receipts.reduce(
                    (acc, item) =>
                      acc +
                      item.quantity *
                        item.rawMaterials.reduce(
                          (acc, rm) =>
                            acc +
                            getTotal({
                              value: rm.value,
                              quantity: rm.quantity,
                              hasAccessory: false,
                              percentage: 0,
                            }),
                          0,
                        ),
                    0,
                  )}
                />
              </Table.Td>
            </Table.Tr>
          }
          keyId="uid"
          loading={isLoadingOrRefetchQuery(blockQuery)}
          loadingMessage="Cargando recetas"
          minWidth={minWidth}
          noDataMessage={
            blockID ? 'No se encontraron recetas' : 'Seleccione una bloque para ver sus recetas'
          }
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            if (!blockQuery.data) return

            const data = blockQuery.data.receipts.filter((item) => ids.includes(item.uid))
            if (!data.length) return

            setModalReceiptDelete({ opened: true, data })
          }}
          onDetail={(data) => {
            setReceiptModalPreview({ opened: true, data })
          }}
          onSearch={(query, field) => setParamsReceipt({ query, field })}
        />
      </div>

      <BlockForm
        modal
        data={modalBlockForm.data}
        open={modalBlockForm.opened}
        size="90%"
        onClose={() => setModalBlockForm({ opened: false })}
        onSubmitted={(block) => {
          setParams({})

          if (block) {
            setBlockID(block.uid)
          }
        }}
      />

      <ReceiptPreview
        modal
        data={modalReceiptPreview.data}
        open={modalReceiptPreview.opened}
        size="85%"
        onClose={() => setReceiptModalPreview({ opened: false })}
      />

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={false}
        opened={modalBlockDelete.opened}
        title="Eliminar bloque"
        onCancel={() => setModalBlockDelete({ opened: false })}
        onConfirm={() => {
          if (!modalBlockDelete.opened || !modalBlockDelete.data) return

          blocksDeleteMutation.mutate(modalBlockDelete.data.uid)
        }}
      >
        <Text className="cd-text-base">
          ¿Estás seguro que deseas eliminar la bloque{' '}
          <Text inherit className="cd-text-base cd-font-bold" component="span">
            {modalBlockDelete.data?.identifier} - {modalBlockDelete.data?.name}
          </Text>
          ?
        </Text>
      </ConfirmationModal>

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={isLoadingMutation(removeReceiptFromBlockMutation)}
        opened={modalReceiptDelete.opened}
        title="Eliminar receta"
        onCancel={() => setModalReceiptDelete({ opened: false })}
        onConfirm={() => {
          if (!modalReceiptDelete.data || !blockQuery.data) return

          removeReceiptFromBlockMutation.mutate({
            blockID: blockQuery.data?.uid,
            receiptIDs: modalReceiptDelete.data.map((item) => item.uid),
          })
        }}
      >
        {modalReceiptDelete.data?.length === 1 && (
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la receta{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalReceiptDelete.data[0].identifier} - {modalReceiptDelete.data[0].name}
            </Text>
            ?
          </Text>
        )}
        {modalReceiptDelete.data && modalReceiptDelete.data.length > 1 && (
          <React.Fragment>
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar las siguientes recetas?
            </Text>
            <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
              {modalReceiptDelete.data.map((item) => (
                <List.Item key={item.uid}>
                  <Text className="cd-text-base">
                    {item.identifier} - {item.name}
                  </Text>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        )}
      </ConfirmationModal>
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'identifier', label: 'ID', width: '5%', type: 'text', searchable: true },
      { key: 'name', label: 'Nombre', width: '10%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '15%', type: 'text', searchable: true },

      {
        key: 'rawMaterials',
        label: 'Materiales',
        width: '15%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ rawMaterials }) => (
          <ol>
            {rawMaterials.map((item) => (
              <li key={item.uid}>
                {item.name}
                <ul>
                  <li>
                    {item.quantity} {MeasuredUnit[item.measure].value} (
                    {<NumberFormat value={item.value} />} c/u)
                  </li>
                  <li>
                    Total:{' '}
                    <NumberFormat className="cd-font-bold" value={item.value * item.quantity} />
                  </li>
                </ul>
              </li>
            ))}
          </ol>
        ),
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '1%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: ({ quantity }) => <NumberFormat skipRole decimals={0} prefix="" value={quantity} />,
      },
      {
        key: 'rawMaterialValue',
        label: 'Valor ($)',
        width: '5%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: ({ rawMaterials }) => (
          <NumberFormat
            value={rawMaterials.reduce(
              (acc, rm) =>
                acc +
                getTotal({
                  value: rm.value,
                  quantity: rm.quantity,
                  hasAccessory: false,
                  percentage: 0,
                }),
              0,
            )}
          />
        ),
      },
      {
        key: 'calc',
        label: 'Total ($)',
        width: '5%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: (item) => (
          <NumberFormat
            value={
              item.quantity *
              item.rawMaterials.reduce(
                (acc, rm) =>
                  acc +
                  getTotal({
                    value: rm.value,
                    quantity: rm.quantity,
                    hasAccessory: false,
                    percentage: 0,
                  }),
                0,
              )
            }
          />
        ),
      },
    ] as GenericColumnType<BlockType['receipts'][0]>[]
  }
}

export default Blocks
