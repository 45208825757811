import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import {
  doc,
  limit,
  query,
  where,
  getDoc,
  setDoc,
  getDocs,
  orderBy,
  updateDoc,
  collection,
  writeBatch,
  startAfter,
  runTransaction,
  QueryConstraint,
} from 'firebase/firestore'

import { Logger } from '@utils/log'
import { ErrorCodes, ErrorService } from '@utils/error'

import { Collections } from '@constants/api'

import {
  GetRawMaterialsType,
  RawMaterialBaseType,
  DeleteRawMaterialType,
  DeleteRawMaterialsType,
  RawMaterialFirebaseType,
  RawMaterialWarehouseType,
  CreateRawMaterialBaseType,
  UpdateRawMaterialBaseType,
  GetRawMaterialWarehouseType,
} from '@customTypes/rawMaterial'

import { WarehouseBaseType, WarehouseFirebaseType } from '@customTypes/warehouse'

import { StockBaseReceiptType, StockBaseRMType } from '@customTypes/stock'

import {
  ReceiptType,
  ReceiptBaseType,
  GetReceiptsType,
  UpdateReceiptType,
  ReceiptsFirebaseType,
  RawMaterialReceiptType,
  DeleteRawMaterialsFromReceiptType,
  CreateReceiptType,
  ReceiptWarehouseType,
} from '@customTypes/receipt'
import { QtyReceiptBaseType, QtyBlockBaseType } from '@customTypes/quantity'
import {
  BlockBaseType,
  BlockFirebaseType,
  BlockType,
  CreateBlockType,
  DeleteReceiptsBlockType,
  GetBlocksType,
  ReceiptBlockType,
  UpdateBlockType,
} from '@customTypes/block'

import {
  CreateToolBaseType,
  DeleteToolsType,
  DeleteToolType,
  GetToolsType,
  GetToolWarehouseType,
  ToolBaseType,
  ToolFirebaseType,
  ToolWarehouseType,
  UpdateToolBaseType,
} from '@customTypes/tool'

import ConstructionEntitiesDS from '@api/domain/ds/ConstructionEntitiesDS'

import {
  deleteAttachments,
  firestore,
  getComposedIDs,
  parseRefToEntity,
  uploadAttachment,
  validateIdentifier,
} from '@utils/firebase'

class ConstructionEntitiesImpl extends ConstructionEntitiesDS {
  static instance: ConstructionEntitiesImpl

  private constructor() {
    super()
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new ConstructionEntitiesImpl()
    }

    return this.instance
  }

  //Raw material
  parseToRawMaterialBase(rawMaterialFirebase: RawMaterialFirebaseType) {
    const {
      uid,
      name,
      description,
      measure,
      alertStock,
      value,
      deleted,
      attachments,
      updatedAt,
      createdAt,
      descriptionProforma,
    } = rawMaterialFirebase

    const rawMaterialBase: RawMaterialBaseType = {
      uid,
      descriptionProforma,
      name,
      description,
      measure,
      alertStock,
      value,
      deleted,
      attachments,
      updatedAt,
      createdAt,
    }

    return rawMaterialBase
  }

  async parseToRawMaterial(warehouseID: string, rawMaterialFirebase: RawMaterialFirebaseType) {
    const {
      uid,
      name,
      description,
      measure,
      alertStock,
      value,
      deleted,
      updatedAt,
      attachments,
      createdAt,
      descriptionProforma,
    } = rawMaterialFirebase

    //Get stock from collection Stock, the id record is `warehouseID-rawMaterialID`
    const { quantity } = await parseRefToEntity<StockBaseRMType>(
      doc(firestore, Collections.STOCKS.NAME, getComposedIDs(warehouseID, uid)),
    )

    const warehouseData = await parseRefToEntity<WarehouseFirebaseType>(
      doc(firestore, Collections.WAREHOUSES.NAME, warehouseID),
    )

    const warehouseBase: WarehouseBaseType = {
      uid: warehouseData.uid,
      name: warehouseData.name,
      inHouse: warehouseData.inHouse,
      deleted: warehouseData.deleted,
      description: warehouseData.description,
      updatedAt: warehouseData.updatedAt,
      createdAt: warehouseData.createdAt,
    }

    const rawMaterialData: RawMaterialWarehouseType = {
      uid,
      name,
      measure,
      value,
      deleted,
      updatedAt,
      alertStock,
      description,
      attachments,
      createdAt,
      stock: quantity,
      warehouse: warehouseBase,
      descriptionProforma,
    }

    return rawMaterialData
  }

  async parseMaterialToRef(materials: (RawMaterialWarehouseType | RawMaterialBaseType)[]) {
    const refs = []

    for (const material of materials) {
      const docRef = doc(firestore, Collections.RAW_MATERIALS.NAME, material.uid)
      refs.push(docRef)
    }

    return refs
  }

  async getRawMaterials(params: GetRawMaterialsType) {
    try {
      const collectionRef = collection(firestore, Collections.RAW_MATERIALS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.field && params.query) {
        if (['value', 'measure'].includes(params.field)) {
          queries.push(where(params.field, '==', params.query))
        }
      } else if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('name', params.orderDir ?? 'asc'))

      const q = query(collectionRef, ...queries)

      const rawMaterialsSnap = await getDocs(q)

      let rawMaterialsData = rawMaterialsSnap.docs.map((doc) =>
        doc.data(),
      ) as RawMaterialFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        rawMaterialsData = rawMaterialsData.filter((rawMaterial) => {
          const value = rawMaterial[params.field as keyof RawMaterialFirebaseType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const rawMaterialsBase: RawMaterialBaseType[] = []

      for (const rawMaterial of rawMaterialsData) {
        rawMaterialsBase.push(this.parseToRawMaterialBase(rawMaterial))
      }

      return rawMaterialsBase
    } catch (error) {
      Logger.error('Error getting raw materials', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RAW_MATERIALS)
    }
  }

  async getRawMaterialById(params: GetRawMaterialWarehouseType) {
    try {
      const { warehouseID, rawMaterialID } = params

      const docRef = doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterialID)

      const rmFirebaseData = await parseRefToEntity<RawMaterialFirebaseType>(docRef)

      const rawMaterialData = await this.parseToRawMaterial(warehouseID, rmFirebaseData)

      return rawMaterialData
    } catch (error) {
      Logger.error('Error getting raw material by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RAW_MATERIALS)
    }
  }

  async getRawMaterialBaseById(id: string) {
    try {
      const docRef = doc(firestore, Collections.RAW_MATERIALS.NAME, id)

      const rawMaterialSnap = await parseRefToEntity<RawMaterialFirebaseType>(docRef)

      return this.parseToRawMaterialBase(rawMaterialSnap)
    } catch (error) {
      Logger.error('Error getting raw material by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RAW_MATERIALS)
    }
  }

  async createRawMaterial(params: CreateRawMaterialBaseType) {
    try {
      const { rawMateria } = params

      const rawMaterialBase = await runTransaction<RawMaterialBaseType>(
        firestore,
        async (transaction) => {
          const uid = uuidv4()
          const attachments: string[] = []

          for (const attachment of rawMateria.attachments) {
            if (attachment.startsWith('data:')) {
              const attachmentPath = await uploadAttachment({
                entity: 'rawMaterial',
                entityId: uid,
                base64: attachment,
              })
              attachments.push(attachmentPath)
            } else {
              attachments.push(attachment)
            }
          }

          const currentTimestamp = moment().unix()

          const newRawMaterial: RawMaterialFirebaseType = {
            descriptionProforma: rawMateria.descriptionProforma,
            description: rawMateria.description,
            alertStock: rawMateria.alertStock,
            measure: rawMateria.measure,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
            value: rawMateria.value,
            name: rawMateria.name,
            deleted: false,
            attachments,
            uid,
          }

          transaction.set(
            doc(firestore, Collections.RAW_MATERIALS.NAME, newRawMaterial.uid),
            newRawMaterial,
          )

          return newRawMaterial as RawMaterialBaseType
        },
      )

      return rawMaterialBase
    } catch (error) {
      Logger.error('Error creating raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_RAW_MATERIAL)
    }
  }

  async updateRawMaterial(params: UpdateRawMaterialBaseType) {
    try {
      await runTransaction(firestore, async (transaction) => {
        const { rawMateria } = params

        const attachments: string[] = []

        if (rawMateria.attachments?.length) {
          const newAttachments: string[] = []

          for (const attachment of rawMateria.attachments) {
            if (attachment.startsWith('data:')) {
              const attachmentPath = await uploadAttachment({
                entity: 'rawMaterial',
                entityId: rawMateria.uid,
                base64: attachment,
              })

              newAttachments.push(attachmentPath)
            } else {
              newAttachments.push(attachment)
            }
          }

          attachments.push(...newAttachments)
        }

        const rawMaterialFirebaseData: Partial<RawMaterialFirebaseType> = {
          descriptionProforma: rawMateria.descriptionProforma,
          description: rawMateria.description,
          alertStock: rawMateria.alertStock,
          deleted: rawMateria.deleted,
          measure: rawMateria.measure,
          value: rawMateria.value,
          name: rawMateria.name,
          attachments,
          updatedAt: moment().unix(),
        }

        transaction.update(
          doc(firestore, Collections.RAW_MATERIALS.NAME, rawMateria.uid),
          rawMaterialFirebaseData,
        )
      })
    } catch (error) {
      Logger.error('Error updating raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_RAW_MATERIAL)
    }
  }

  async deleteRawMaterial(params: DeleteRawMaterialType) {
    try {
      const { rawMaterialID } = params

      await runTransaction(firestore, async (transaction) => {
        const rawMaterialRef = doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterialID)

        const rawMaterialSnap = await transaction.get(rawMaterialRef)

        const rawMaterialData = rawMaterialSnap.data() as RawMaterialFirebaseType

        await deleteAttachments({
          attachmentsPath: rawMaterialData.attachments,
        })

        transaction.update(rawMaterialRef, { deleted: true, attachments: [] })

        // transaction.delete(rawMaterialRef)

        // //check all stock that use this raw material
        // const stockCollectionRef = collection(firestore, Collections.STOCKS.NAME)

        // const stockQuery = query(stockCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        // const stockSnap = await getDocs(stockQuery)

        // for (const doc of stockSnap.docs) {
        //   transaction.delete(doc.ref)
        // }

        // //delete commits
        // const commitCollectionRef = collection(firestore, Collections.COMMITS.NAME)

        // const commitQuery = query(commitCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        // const commitSnap = await getDocs(commitQuery)

        // for (const doc of commitSnap.docs) {
        //   transaction.delete(doc.ref)
        // }
      })
    } catch (error) {
      Logger.error('Error deleting raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIAL)
    }
  }

  async deleteRawMaterials(params: DeleteRawMaterialsType) {
    try {
      const { rawMaterialIDs } = params

      for (const rawMaterialID of rawMaterialIDs) {
        //check all stock that use this raw material
        // const stockCollectionRef = collection(firestore, Collections.STOCKS.NAME)

        // const stockQuery = query(stockCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        // const stockSnap = await getDocs(stockQuery)

        // for (const doc of stockSnap.docs) {
        //   await deleteDoc(doc.ref)
        // }

        const rawMaterialRef = doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterialID)

        const rawMaterialSnap = await getDoc(rawMaterialRef)

        const rawMaterialData = rawMaterialSnap.data() as RawMaterialFirebaseType

        await deleteAttachments({
          attachmentsPath: rawMaterialData.attachments,
        })

        await updateDoc(rawMaterialRef, { deleted: true, attachments: [] })

        //delete commits
        // const commitCollectionRef = collection(firestore, Collections.COMMITS.NAME)

        // const commitQuery = query(commitCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        // const commitSnap = await getDocs(commitQuery)

        // for (const doc of commitSnap.docs) {
        //   await deleteDoc(doc.ref)
        // }
      }
    } catch (error) {
      Logger.error('Error deleting raw materials', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIALS)
    }
  }

  //Tools
  parseToToolBase(toolFirebase: ToolFirebaseType) {
    const {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      alertStock,
      attachments,
      createdAt,
      value,
    } = toolFirebase

    const toolBase: ToolBaseType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      alertStock,
      attachments,
      createdAt,
      value,
    }

    return toolBase
  }

  async parseToTool(warehouseID: string, toolFirebase: ToolFirebaseType) {
    const {
      uid,
      name,
      description,
      alertStock,
      value,
      deleted,
      updatedAt,
      attachments,
      createdAt,
    } = toolFirebase

    const { quantity } = await parseRefToEntity<StockBaseRMType>(
      doc(firestore, Collections.STOCKS_TOOLS.NAME, getComposedIDs(warehouseID, uid)),
    )

    const warehouseData = await parseRefToEntity<WarehouseFirebaseType>(
      doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, warehouseID),
    )

    const warehouseBase: WarehouseBaseType = {
      uid: warehouseData.uid,
      name: warehouseData.name,
      inHouse: warehouseData.inHouse,
      deleted: warehouseData.deleted,
      description: warehouseData.description,
      updatedAt: warehouseData.updatedAt,
      createdAt: warehouseData.createdAt,
    }

    const toolData: ToolWarehouseType = {
      uid,
      name,
      value,
      deleted,
      updatedAt,
      alertStock,
      description,
      attachments,
      createdAt,
      stock: quantity,
      warehouseTool: warehouseBase,
    }

    return toolData
  }

  async parseToolToRef(tools: (ToolWarehouseType | ToolBaseType)[]) {
    const refs = []

    for (const tool of tools) {
      const docRef = doc(firestore, Collections.TOOLS.NAME, tool.uid)
      refs.push(docRef)
    }

    return refs
  }

  async getTools(params: GetToolsType) {
    try {
      const collectionRef = collection(firestore, Collections.TOOLS.NAME)

      const queries: QueryConstraint[] = []

      if (params.field && params.query) {
        if (['value'].includes(params.field)) {
          queries.push(where(params.field, '==', params.query))
        }
      } else if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('name', params.orderDir ?? 'asc'))

      const q = query(collectionRef, ...queries)

      const toolsSnap = await getDocs(q)

      let toolsData = toolsSnap.docs.map((doc) => doc.data()) as ToolFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        toolsData = toolsData.filter((tool) => {
          const value = tool[params.field as keyof ToolFirebaseType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const toolsBase: ToolBaseType[] = []

      for (const tool of toolsData) {
        toolsBase.push(this.parseToToolBase(tool))
      }

      return toolsBase
    } catch (error) {
      Logger.error('Error getting tools', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_TOOLS)
    }
  }

  async getToolById(params: GetToolWarehouseType) {
    try {
      const { warehouseToolID, toolID } = params

      const docRef = doc(firestore, Collections.TOOLS.NAME, toolID)

      const toolFirebaseData = await parseRefToEntity<ToolFirebaseType>(docRef)

      const toolData = await this.parseToTool(warehouseToolID, toolFirebaseData)

      return toolData
    } catch (error) {
      Logger.error('Error getting tool by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_TOOLS)
    }
  }

  async getToolBaseById(id: string) {
    try {
      const docRef = doc(firestore, Collections.TOOLS.NAME, id)

      const toolSnap = await parseRefToEntity<ToolFirebaseType>(docRef)

      return this.parseToToolBase(toolSnap)
    } catch (error) {
      Logger.error('Error getting tool by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_TOOLS)
    }
  }

  async createTool(params: CreateToolBaseType) {
    try {
      const { tool } = params

      const toolBase = await runTransaction<ToolBaseType>(firestore, async (transaction) => {
        const uid = uuidv4()
        const attachments: string[] = []

        for (const attachment of tool.attachments) {
          if (attachment.startsWith('data:')) {
            const attachmentPath = await uploadAttachment({
              entity: 'tool',
              entityId: uid,
              base64: attachment,
            })
            attachments.push(attachmentPath)
          } else {
            attachments.push(attachment)
          }
        }

        const currentTimestamp = moment().unix()

        const newTool: ToolFirebaseType = {
          description: tool.description,
          alertStock: tool.alertStock,
          value: tool.value,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          name: tool.name,
          deleted: false,
          attachments,
          uid,
        }

        transaction.set(doc(firestore, Collections.TOOLS.NAME, newTool.uid), newTool)

        return newTool as ToolBaseType
      })

      return toolBase
    } catch (error) {
      Logger.error('Error creating tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_TOOL)
    }
  }

  async updateTool(params: UpdateToolBaseType) {
    try {
      await runTransaction(firestore, async (transaction) => {
        const { tool } = params

        const attachments: string[] = []

        if (tool.attachments?.length) {
          const newAttachments: string[] = []

          for (const attachment of tool.attachments) {
            if (attachment.startsWith('data:')) {
              const attachmentPath = await uploadAttachment({
                entity: 'tool',
                entityId: tool.uid,
                base64: attachment,
              })

              newAttachments.push(attachmentPath)
            } else {
              newAttachments.push(attachment)
            }
          }

          attachments.push(...newAttachments)
        }

        const toolFirebaseData: Partial<ToolFirebaseType> = {
          description: tool.description,
          alertStock: tool.alertStock,
          deleted: tool.deleted,
          value: tool.value,
          name: tool.name,
          attachments,
          updatedAt: moment().unix(),
        }

        transaction.update(doc(firestore, Collections.TOOLS.NAME, tool.uid), toolFirebaseData)
      })
    } catch (error) {
      Logger.error('Error updating tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_TOOL)
    }
  }

  async deleteTool(params: DeleteToolType) {
    try {
      const { toolID } = params

      await runTransaction(firestore, async (transaction) => {
        const toolRef = doc(firestore, Collections.TOOLS.NAME, toolID)

        const toolSnap = await transaction.get(toolRef)

        const toolData = toolSnap.data() as ToolFirebaseType

        await deleteAttachments({
          attachmentsPath: toolData.attachments,
        })

        transaction.update(toolRef, { deleted: true, attachments: [] })

        // transaction.delete(toolRef)

        // //check all stock that use this tool
        // const stockCollectionRef = collection(firestore, Collections.STOCKS_TOOLS.NAME)

        // const stockQuery = query(stockCollectionRef, where('toolID', '==', toolID))

        // const stockSnap = await getDocs(stockQuery)

        // for (const doc of stockSnap.docs) {
        //   transaction.delete(doc.ref)
        // }
      })
    } catch (error) {
      Logger.error('Error deleting tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_TOOL)
    }
  }

  async deleteTools(params: DeleteToolsType) {
    try {
      const { toolIDs } = params

      const batch = writeBatch(firestore)

      for (const toolID of toolIDs) {
        //check all stock that use this tool

        // const stockCollectionRef = collection(firestore, Collections.STOCKS_TOOLS.NAME)

        // const stockQuery = query(stockCollectionRef, where('toolID', '==', toolID))

        // const stockSnap = await getDocs(stockQuery)

        // for (const doc of stockSnap.docs) {
        //   await deleteDoc(doc.ref)
        // }

        const toolRef = doc(firestore, Collections.TOOLS.NAME, toolID)

        const toolSnap = await getDoc(toolRef)

        const toolData = toolSnap.data() as ToolFirebaseType

        await deleteAttachments({
          attachmentsPath: toolData.attachments,
        })

        batch.update(toolRef, { deleted: true, attachments: [] })

        // await deleteDoc(toolRef)
      }
    } catch (error) {
      Logger.error('Error deleting tools', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_TOOL)
    }
  }

  //Receipts
  parseToReceiptBase(receiptFirebase: ReceiptsFirebaseType) {
    const { uid, identifier, name, description, deleted, updatedAt, createdAt } = receiptFirebase

    const receiptBase: ReceiptBaseType = {
      uid,
      name,
      identifier,
      description,
      deleted,
      updatedAt,
      createdAt,
    }

    return receiptBase
  }

  async parseToReceipt(receiptFirebase: ReceiptsFirebaseType) {
    const { uid, identifier, name, description, deleted, updatedAt, createdAt } = receiptFirebase

    const rawMaterials: RawMaterialReceiptType[] = []

    for (const rawMaterialRef of receiptFirebase.rawMaterials) {
      const rawMaterialData = await parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

      const qtyReceipt = doc(
        firestore,
        Collections.QTY_RECEIPTS.NAME,
        getComposedIDs(uid, rawMaterialData.uid),
      )
      const qtyReceiptData = await parseRefToEntity<QtyReceiptBaseType>(qtyReceipt)

      const rawMaterialReceipt: RawMaterialReceiptType = {
        uid: rawMaterialData.uid,
        name: rawMaterialData.name,
        measure: rawMaterialData.measure,
        value: rawMaterialData.value,
        deleted: rawMaterialData.deleted,
        updatedAt: rawMaterialData.updatedAt,
        createdAt: rawMaterialData.createdAt,
        alertStock: rawMaterialData.alertStock,
        description: rawMaterialData.description,
        attachments: rawMaterialData.attachments,
        descriptionProforma: rawMaterialData.descriptionProforma,
        quantity: qtyReceiptData.quantity,
      }
      rawMaterials.push(rawMaterialReceipt)
    }

    const receiptData: ReceiptType = {
      uid,
      name,
      identifier,
      description,
      deleted,
      updatedAt,
      createdAt,
      rawMaterials,
    }

    return receiptData
  }

  async parseToReceiptWarehouse(warehouseID: string, receiptFirebase: ReceiptsFirebaseType) {
    const {
      uid,
      identifier,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      rawMaterials,
      descriptionProforma,
    } = await this.parseToReceipt(receiptFirebase)

    //Get stock from collection Stock, the id record is `warehouseID-receiptID`
    const { quantity } = await parseRefToEntity<StockBaseReceiptType>(
      doc(firestore, Collections.STOCKS.NAME, getComposedIDs(warehouseID, uid)),
    )

    const warehouseData = await parseRefToEntity<WarehouseFirebaseType>(
      doc(firestore, Collections.WAREHOUSES.NAME, warehouseID),
    )

    const warehouseBase: WarehouseBaseType = {
      uid: warehouseData.uid,
      name: warehouseData.name,
      inHouse: warehouseData.inHouse,
      deleted: warehouseData.deleted,
      description: warehouseData.description,
      updatedAt: warehouseData.updatedAt,
      createdAt: warehouseData.createdAt,
    }

    const receiptData: ReceiptWarehouseType = {
      uid,
      name,
      identifier,
      description,
      deleted,
      updatedAt,
      createdAt,
      rawMaterials,
      stock: quantity,
      descriptionProforma,
      warehouse: warehouseBase,
    }

    return receiptData
  }

  async getReceipts(params: GetReceiptsType) {
    try {
      const collectionRef = collection(firestore, Collections.RECEIPTS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const receiptsSnap = await getDocs(q)

      let receiptsData = receiptsSnap.docs.map((doc) => doc.data()) as ReceiptsFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        receiptsData = receiptsData.filter((receipt) => {
          const value = receipt[params.field as keyof ReceiptType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const receiptsBase: ReceiptBaseType[] = []

      for (const receipt of receiptsData) {
        receiptsBase.push(this.parseToReceiptBase(receipt))
      }

      return receiptsBase
    } catch (error) {
      Logger.error('Error getting receipts', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RECEIPTS)
    }
  }

  async getReceiptsExtended(params: GetReceiptsType) {
    try {
      const collectionRef = collection(firestore, Collections.RECEIPTS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const receiptsSnap = await getDocs(q)

      let receiptsData = receiptsSnap.docs.map((doc) => doc.data()) as ReceiptsFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        receiptsData = receiptsData.filter((receipt) => {
          const value = receipt[params.field as keyof ReceiptType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const receipts: ReceiptType[] = []

      for (const receipt of receiptsData) {
        const receiptData = await this.parseToReceipt(receipt)

        receipts.push(receiptData)
      }

      return receipts
    } catch (error) {
      Logger.error('Error getting receipts', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RECEIPTS)
    }
  }

  async getReceiptById(id: string) {
    try {
      //try cache
      // const dataByID = queryClient.getQueryData<ReceiptType>([QueryKeys.GET_RECEIPT_KEY, id])

      // if (dataByID) {
      //   return dataByID
      // }

      const docRef = doc(firestore, Collections.RECEIPTS.NAME, id)

      const receiptSnap = await getDoc(docRef)

      const receiptFirebaseData = receiptSnap.data() as ReceiptsFirebaseType

      const receiptData = await this.parseToReceipt(receiptFirebaseData)

      return receiptData
    } catch (error) {
      Logger.error('Error getting receipt by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RECEIPTS)
    }
  }

  async createReceipt(data: CreateReceiptType) {
    try {
      const currentTimestamp = moment().unix()

      const newReceipt: ReceiptsFirebaseType = {
        ...data,
        uid: uuidv4(),
        deleted: false,
        rawMaterials: [],
        updatedAt: currentTimestamp,
        createdAt: currentTimestamp,
      }

      const validIdentifier = await validateIdentifier(
        Collections.RECEIPTS.NAME,
        newReceipt.identifier,
      )

      if (!validIdentifier) {
        throw ErrorService.get(ErrorCodes.ERROR_IDENTIFIER_ALREADY_EXISTS)
      }

      for (const rawMaterial of data.rawMaterials) {
        const rawMaterialRef = doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)

        const qtyReceipt: QtyReceiptBaseType = {
          receiptID: newReceipt.uid,
          rawMaterialID: rawMaterial.uid,
          quantity: rawMaterial.quantity,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
        }

        const qtyReceiptRef = doc(
          firestore,
          Collections.QTY_RECEIPTS.NAME,
          getComposedIDs(newReceipt.uid, rawMaterial.uid),
        )

        await setDoc(qtyReceiptRef, qtyReceipt)

        newReceipt.rawMaterials.push(rawMaterialRef)
      }

      await setDoc(doc(firestore, Collections.RECEIPTS.NAME, newReceipt.uid), newReceipt)

      const receiptData: ReceiptType = {
        uid: newReceipt.uid,
        identifier: newReceipt.identifier,
        name: newReceipt.name,
        description: newReceipt.description,
        descriptionProforma: newReceipt.descriptionProforma,
        deleted: newReceipt.deleted,
        updatedAt: newReceipt.updatedAt,
        createdAt: newReceipt.createdAt,
        rawMaterials: data.rawMaterials,
      }

      return receiptData
    } catch (error) {
      Logger.error('Error creating receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_RECEIPT)
    }
  }

  async updateReceipt(params: UpdateReceiptType) {
    try {
      const { current, previous } = params

      const docRef = doc(firestore, Collections.RECEIPTS.NAME, previous.uid)

      const updateReceipt: Partial<ReceiptsFirebaseType> = {
        rawMaterials: [],
      }

      if (current.identifier && current.identifier !== previous.identifier) {
        updateReceipt.identifier = current.identifier

        const validIdentifier = await validateIdentifier(
          Collections.RECEIPTS.NAME,
          current.identifier,
        )

        if (!validIdentifier) {
          throw ErrorService.get(ErrorCodes.ERROR_IDENTIFIER_ALREADY_EXISTS)
        }
      }

      if (current.name && current.name !== previous.name) {
        updateReceipt.name = current.name
      }
      if (current.description && current.description !== previous.description) {
        updateReceipt.description = current.description
      }
      if (
        current.descriptionProforma &&
        current.descriptionProforma !== previous.descriptionProforma
      ) {
        updateReceipt.descriptionProforma = current.descriptionProforma
      }
      if (current.rawMaterials) {
        for (const rawMaterial of current.rawMaterials) {
          const currentTimestamp = moment().unix()

          const qtyReceipt: QtyReceiptBaseType = {
            rawMaterialID: rawMaterial.uid,
            quantity: rawMaterial.quantity,
            receiptID: previous.uid,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          const qtyReceiptRef = doc(
            firestore,
            Collections.QTY_RECEIPTS.NAME,
            getComposedIDs(previous.uid, rawMaterial.uid),
          )

          await setDoc(qtyReceiptRef, qtyReceipt)

          if (updateReceipt.rawMaterials) {
            updateReceipt.rawMaterials.push(
              doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterial.uid),
            )
          }
        }
      }

      if (Object.keys(updateReceipt).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      await updateDoc(docRef, updateReceipt)
    } catch (error) {
      Logger.error('Error updating receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_RECEIPT)
    }
  }

  async deleteReceipt(id: string) {
    try {
      const docRef = doc(firestore, Collections.RECEIPTS.NAME, id)

      await updateDoc(docRef, { deleted: true })

      // await deleteDoc(docRef)

      // const qtyReceiptCollectionRef = collection(firestore, Collections.QTY_RECEIPTS.NAME)

      // const qtyReceiptQuery = query(qtyReceiptCollectionRef, where('receiptID', '==', id))

      // const qtyReceiptSnap = await getDocs(qtyReceiptQuery)

      // for (const doc of qtyReceiptSnap.docs) {
      //   await deleteDoc(doc.ref)
      // }
    } catch (error) {
      Logger.error('Error deleting receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RECEIPT)
    }
  }

  async deleteReceipts(ids: string[]) {
    try {
      const batch = writeBatch(firestore)

      for (const id of ids) {
        const docRef = doc(firestore, Collections.RECEIPTS.NAME, id)

        batch.update(docRef, { deleted: true })

        // batch.delete(docRef)

        // const qtyReceiptCollectionRef = collection(firestore, Collections.QTY_RECEIPTS.NAME)

        // const qtyReceiptQuery = query(qtyReceiptCollectionRef, where('receiptID', '==', id))

        // const qtyReceiptSnap = await getDocs(qtyReceiptQuery)

        // for (const doc of qtyReceiptSnap.docs) {
        //   batch.delete(doc.ref)
        // }
      }

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting receipts', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RECEIPTS)
    }
  }

  async deleteRawMaterialsFromReceipt(params: DeleteRawMaterialsFromReceiptType) {
    try {
      const { receiptID, rawMaterialIDs } = params

      await runTransaction(firestore, async (transaction) => {
        const receiptRef = doc(firestore, Collections.RECEIPTS.NAME, receiptID)

        const receiptSnap = await transaction.get(receiptRef)

        const receiptData = receiptSnap.data() as ReceiptsFirebaseType

        for (const rawMaterialID of rawMaterialIDs) {
          const qtyReceiptRef = doc(
            firestore,
            Collections.QTY_RECEIPTS.NAME,
            getComposedIDs(receiptID, rawMaterialID),
          )

          transaction.delete(qtyReceiptRef)

          const index = receiptData.rawMaterials.findIndex((rm) => rm.id === rawMaterialID)

          if (index !== -1) {
            receiptData.rawMaterials.splice(index, 1)
          }
        }

        transaction.update(receiptRef, {
          rawMaterials: receiptData.rawMaterials,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting raw materials from receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIALS_FROM_RECEIPT)
    }
  }

  //Blocks
  parseToBlockBase(blockFirebase: BlockFirebaseType) {
    const {
      uid,
      identifier,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      descriptionProforma,
    } = blockFirebase

    const blockBase: BlockBaseType = {
      uid,
      identifier,
      name,
      deleted,
      updatedAt,
      createdAt,
      description,
      descriptionProforma,
    }

    return blockBase
  }

  async parseToBlock(blockFirebase: BlockFirebaseType) {
    const {
      uid,
      identifier,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      descriptionProforma,
    } = blockFirebase

    const receipts: ReceiptBlockType[] = []

    for (const receiptRef of blockFirebase.receipts) {
      const receiptFirebaseData = await parseRefToEntity<ReceiptsFirebaseType>(receiptRef)

      const receiptData = await this.parseToReceipt(receiptFirebaseData)

      const qtyBlock = doc(
        firestore,
        Collections.QTY_BLOCKS.NAME,
        getComposedIDs(uid, receiptFirebaseData.uid),
      )

      const qtyBlockData = await parseRefToEntity<QtyBlockBaseType>(qtyBlock)

      const receiptBlock: ReceiptBlockType = {
        uid: receiptData.uid,
        identifier: receiptData.identifier,
        name: receiptData.name,
        description: receiptData.description,
        deleted: receiptData.deleted,
        updatedAt: receiptData.updatedAt,
        createdAt: receiptData.createdAt,
        rawMaterials: receiptData.rawMaterials,
        descriptionProforma: receiptData.descriptionProforma,
        quantity: qtyBlockData.quantity,
      }

      receipts.push(receiptBlock)
    }

    const blockData: BlockType = {
      uid,
      identifier,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      receipts,
      descriptionProforma,
    }

    return blockData
  }

  async getBlocks(params: GetBlocksType) {
    try {
      const collectionRef = collection(firestore, Collections.BLOCKS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const blocksSnap = await getDocs(q)

      const blocksData = blocksSnap.docs.map((doc) => doc.data()) as BlockFirebaseType[]

      const blocksBase: BlockBaseType[] = []

      for (const block of blocksData) {
        blocksBase.push(this.parseToBlockBase(block))
      }

      return blocksBase
    } catch (error) {
      Logger.error('Error getting blocks', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_BLOCKS)
    }
  }

  async getBlocksExtended(params: GetBlocksType) {
    try {
      const collectionRef = collection(firestore, Collections.BLOCKS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const blocksSnap = await getDocs(q)

      const blocksData = blocksSnap.docs.map((doc) => doc.data()) as BlockFirebaseType[]

      const blocks: BlockType[] = []

      for (const block of blocksData) {
        const blockData = await this.parseToBlock(block)

        blocks.push(blockData)
      }

      return blocks
    } catch (error) {
      Logger.error('Error getting blocks', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_BLOCKS)
    }
  }

  async getBlockById(id: string) {
    try {
      const docRef = doc(firestore, Collections.BLOCKS.NAME, id)

      const blockSnap = await getDoc(docRef)

      const blockFirebaseData = blockSnap.data() as BlockFirebaseType

      const blockData = await this.parseToBlock(blockFirebaseData)

      return blockData
    } catch (error) {
      Logger.error('Error getting block by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_BLOCKS)
    }
  }

  async createBlock(data: CreateBlockType) {
    try {
      const currentTimestamp = moment().unix()

      const newBlock: BlockFirebaseType = {
        ...data,
        uid: uuidv4(),
        deleted: false,
        receipts: [],
        updatedAt: currentTimestamp,
        createdAt: currentTimestamp,
      }

      const validIdentifier = await validateIdentifier(Collections.BLOCKS.NAME, newBlock.identifier)

      if (!validIdentifier) {
        throw ErrorService.get(ErrorCodes.ERROR_IDENTIFIER_ALREADY_EXISTS)
      }

      for (const receipt of data.receipts) {
        const receiptRef = doc(firestore, Collections.RECEIPTS.NAME, receipt.uid)

        const currentTimestamp = moment().unix()

        const qtyBlock: QtyBlockBaseType = {
          blockID: newBlock.uid,
          receiptID: receipt.uid,
          quantity: receipt.quantity,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
        }

        const qtyBlockRef = doc(
          firestore,
          Collections.QTY_BLOCKS.NAME,
          getComposedIDs(newBlock.uid, receipt.uid),
        )

        await setDoc(qtyBlockRef, qtyBlock)

        newBlock.receipts.push(receiptRef)
      }

      await setDoc(doc(firestore, Collections.BLOCKS.NAME, newBlock.uid), newBlock)

      const blockData: BlockType = {
        uid: newBlock.uid,
        identifier: newBlock.identifier,
        name: newBlock.name,
        description: newBlock.description,
        deleted: newBlock.deleted,
        updatedAt: newBlock.updatedAt,
        createdAt: newBlock.createdAt,
        receipts: data.receipts,
        descriptionProforma: newBlock.descriptionProforma,
      }

      return blockData
    } catch (error) {
      Logger.error('Error creating block', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_BLOCK)
    }
  }

  async updateBlock(params: UpdateBlockType) {
    try {
      const { current, previous } = params

      const docRef = doc(firestore, Collections.BLOCKS.NAME, previous.uid)

      const updateBlock: Partial<BlockFirebaseType> = {
        receipts: [],
      }

      if (current.identifier && current.identifier !== previous.uid) {
        updateBlock.uid = current.identifier

        const validIdentifier = await validateIdentifier(
          Collections.BLOCKS.NAME,
          current.identifier,
        )

        if (!validIdentifier) {
          throw ErrorService.get(ErrorCodes.ERROR_IDENTIFIER_ALREADY_EXISTS)
        }
      }

      if (current.name && current.name !== previous.name) {
        updateBlock.name = current.name
      }
      if (current.description && current.description !== previous.description) {
        updateBlock.description = current.description
      }
      if (
        current.descriptionProforma &&
        current.descriptionProforma !== previous.descriptionProforma
      ) {
        updateBlock.descriptionProforma = current.descriptionProforma
      }
      if (current.receipts) {
        for (const receipt of current.receipts) {
          const currentTimestamp = moment().unix()

          const qtyBlock: QtyBlockBaseType = {
            blockID: previous.uid,
            quantity: receipt.quantity,
            receiptID: receipt.uid,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          const qtyBlockRef = doc(
            firestore,
            Collections.QTY_BLOCKS.NAME,
            getComposedIDs(previous.uid, receipt.uid),
          )

          await setDoc(qtyBlockRef, qtyBlock)

          if (updateBlock.receipts) {
            updateBlock.receipts.push(doc(firestore, Collections.RECEIPTS.NAME, receipt.uid))
          }
        }
      }

      if (Object.keys(updateBlock).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      await updateDoc(docRef, updateBlock)
    } catch (error) {
      Logger.error('Error updating block', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_BLOCK)
    }
  }

  async deleteBlock(id: string) {
    try {
      const docRef = doc(firestore, Collections.BLOCKS.NAME, id)

      await updateDoc(docRef, { deleted: true })

      // await deleteDoc(docRef)

      // const qtyBlockCollectionRef = collection(firestore, Collections.QTY_BLOCKS.NAME)

      // const qtyBlockQuery = query(qtyBlockCollectionRef, where('blockID', '==', id))

      // const qtyBlockSnap = await getDocs(qtyBlockQuery)

      // for (const doc of qtyBlockSnap.docs) {
      //   await deleteDoc(doc.ref)
      // }
    } catch (error) {
      Logger.error('Error deleting block', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_BLOCK)
    }
  }

  async deleteBlocks(ids: string[]) {
    try {
      const batch = writeBatch(firestore)

      for (const id of ids) {
        const docRef = doc(firestore, Collections.BLOCKS.NAME, id)

        batch.update(docRef, { deleted: true })

        // batch.delete(docRef)

        // const qtyBlockCollectionRef = collection(firestore, Collections.QTY_BLOCKS.NAME)

        // const qtyBlockQuery = query(qtyBlockCollectionRef, where('blockID', '==', id))

        // const qtyBlockSnap = await getDocs(qtyBlockQuery)

        // for (const doc of qtyBlockSnap.docs) {
        //   batch.delete(doc.ref)
        // }
      }

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting blocks', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_BLOCKS)
    }
  }

  async deleteReceiptsFromBlock(params: DeleteReceiptsBlockType) {
    try {
      const { blockID, receiptIDs } = params

      await runTransaction(firestore, async (transaction) => {
        const blockRef = doc(firestore, Collections.BLOCKS.NAME, blockID)

        const blockSnap = await transaction.get(blockRef)

        const blockData = blockSnap.data() as BlockFirebaseType

        for (const receiptID of receiptIDs) {
          const qtyBlockRef = doc(
            firestore,
            Collections.QTY_BLOCKS.NAME,
            getComposedIDs(blockID, receiptID),
          )

          transaction.delete(qtyBlockRef)

          const index = blockData.receipts.findIndex((rm) => rm.id === receiptID)

          if (index !== -1) {
            blockData.receipts.splice(index, 1)
          }
        }

        transaction.update(blockRef, {
          receipts: blockData.receipts,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting receipts from block', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RECEIPTS_FROM_BLOCK)
    }
  }
}

export default ConstructionEntitiesImpl
