import z from 'zod'

import { MAX_ATTACHMENTS, MeasuredUnit } from '@constants/rawMaterial'

import { GetEntitySchema } from './network'
import { WarehouseBaseSchema } from './warehouse'

export const MeasureSchema = z.custom<keyof typeof MeasuredUnit>(
  (value) => Object.keys(MeasuredUnit).includes(value),
  {
    message: 'Unidad de medida inválida',
  },
)

// User when get all raw materials
export const RawMaterialBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(100, 'Descripción debe tener como máximo 100 caracteres').trim(),
  descriptionProforma: z
    .string()
    .max(750, 'Descripción de proforma debe tener como máximo 750 caracteres')
    .trim()
    .optional()
    .nullable(),
  measure: MeasureSchema,
  alertStock: z.number(),
  value: z.number().positive('Valor debe ser mayor a 0'),
  deleted: z.boolean().optional(),
  attachments: z
    .array(z.string())
    .max(MAX_ATTACHMENTS, `Máximo ${MAX_ATTACHMENTS} archivos adjuntos`),
  updatedAt: z.number(),
  createdAt: z.number(),
})

export type RawMaterialBaseType = z.infer<typeof RawMaterialBaseSchema>

// Internal use
export const RawMaterialFirebaseSchema = RawMaterialBaseSchema

export type RawMaterialFirebaseType = z.infer<typeof RawMaterialFirebaseSchema>

// User when get a raw material by id AND specific warehouse
export const RawMaterialWarehouseSchema = RawMaterialBaseSchema.extend({
  stock: z.number().positive('Stock debe ser mayor a 0'),
  warehouse: z.lazy(() => WarehouseBaseSchema),
})

export type RawMaterialWarehouseType = z.infer<typeof RawMaterialWarehouseSchema>

//
// CRUD OPERATIONS
//
//Create (from warehouse)
export const CreateRawMaterialBaseSchema = z.object({
  //warehouseID: z.string(),
  rawMateria: RawMaterialBaseSchema.omit({
    createdAt: true,
    updatedAt: true,
    deleted: true,
    uid: true,
  }),
})

export type CreateRawMaterialBaseType = z.infer<typeof CreateRawMaterialBaseSchema>

//Update
export const UpdateRawMaterialBaseSchema = z.object({
  //warehouseID: z.string(),
  rawMateria: RawMaterialBaseSchema.partial().extend({
    uid: z.string(),
  }),
})

export type UpdateRawMaterialBaseType = z.infer<typeof UpdateRawMaterialBaseSchema>

//Delete
export const DeleteRawMaterialSchema = z.object({
  //warehouseID: z.string(),
  rawMaterialID: z.string(),
})

export type DeleteRawMaterialType = z.infer<typeof DeleteRawMaterialSchema>

export const DeleteRawMaterialsSchema = z.object({
  //warehouseID: z.string(),
  rawMaterialIDs: z.array(z.string()),
})

export type DeleteRawMaterialsType = z.infer<typeof DeleteRawMaterialsSchema>

//
// GET OPERATIONS
//
//Get all material from all warehouses
export const GetRawMaterialsSchema = GetEntitySchema

export type GetRawMaterialsType = z.infer<typeof GetRawMaterialsSchema>

// Get materials by warehouse
export const GetRawMaterialsWarehouseSchema = GetEntitySchema.extend({
  warehouseID: z.string(),
})

export type GetRawMaterialsWarehouseType = z.infer<typeof GetRawMaterialsWarehouseSchema>

//Get material by id AND specific warehouse
export const GetRawMaterialWarehouseSchema = z.object({
  warehouseID: z.string(),
  rawMaterialID: z.string(),
})

export type GetRawMaterialWarehouseType = z.infer<typeof GetRawMaterialWarehouseSchema>
